import React, { useState, FunctionComponent } from "react";
import { useMutation, gql } from "@apollo/client";
import axios from "axios";
import { Button, Upload, message } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";

const unsafeAndReservedCharRegex = /(\&|\$|\+|\,|\/|\:|\;|\=|\?|\@|\||\#|\ |\<|\>|\[|\]|\{|\}|\||\\|\^|\%|\~)/ig;

const generateFileName = (file: File) => {
	let timestamp = new Date().getTime();
	const tmpFileName = `${timestamp}_${file.name.replace(/ /ig, "")}`;
	const cleanedFileName = tmpFileName.replace(unsafeAndReservedCharRegex, '_');
	return cleanedFileName;
};

const Mutations = {
	getSignedUrl: gql`
    mutation GetSignedUrl($data: FileSignedURLInput!){
      getSignedUrl(data: $data) {
        signedRequest
        url
      }
    }
  `,
};

interface FileSignedURLInput{
  fileName: string;
  contentType: string;
  folder: string;
}

interface FileSignedURLResponse{
  getSignedUrl: {
    url: string;
    signedRequest: string;
  }
}

interface FileUploadProps{
  folder: string;
  placeholder: string;
  buttonType?: "default" | "primary" | "link" | "text";
  removeHorizontalPadding?: boolean;
  onFileUpload: (url: string, fileName?: string) => void;
}

const FileUpload: FunctionComponent<FileUploadProps> = (props: FileUploadProps) => {
	const { placeholder, buttonType, removeHorizontalPadding=false } = props;
	const [fileList, setFileList] = useState<File[]>([]);

	const [ getSignedUrl ] = useMutation<FileSignedURLResponse, { data: FileSignedURLInput }>(Mutations.getSignedUrl);
	return (
		<Upload
			customRequest={async (e) => {
				const file = e.file as File;
				
				setFileList([file]);
				if (file) {
					message.loading("Uploading attachment",0);
					try {
						const resp = await getSignedUrl({
							variables: {
								data: {
									fileName: generateFileName(file),
									contentType: file.type,
									folder: props.folder,
								}
							}
						});

                        if (!resp || !resp.data) throw new Error("File upload error");
            
						const uploadURL = resp.data.getSignedUrl.signedRequest;
						const fileURL = resp.data.getSignedUrl.url;

						var options = {
							headers: {
								"Content-Type": file.type,
							},
						};

						console.log(`OPTIONS`, options);

						try {
							await axios.put(uploadURL, file, options);
							// e.onSuccess({}, file);
							message.destroy();
							message.success("File Uploaded");
							if (props.onFileUpload){
								props.onFileUpload(fileURL, file.name);
							} 
						} catch (uploadError) {
							console.log(uploadError);
						}
					} catch (e) {
						message.error("File upload error!");
					}
				}
			}}
			multiple={false}
			fileList={[]}
			onRemove={() => {
				setFileList([]);
			}}
		>
			<Button style={removeHorizontalPadding ? {padding: "4px 0" }: undefined} type={buttonType ? buttonType : "default"}>
				<PaperClipOutlined /> {placeholder}
			</Button>
		</Upload>
	);
};

export default FileUpload;