import { gql } from "@apollo/client";

export const PostsQueries = {
    getPost: gql`
        query GetPost($id: Int!) {
            getPost(id: $id) {
                id
                type
                source_name
                timestamp
                last_update_at
                author_id
                author {
                    id
                    email
                    full_name
                    admin
                    editor
                }
                primary_rating
                secondary_rating
                en_title
                si_title
                ta_title
                featured_image_url
                featured_image_filename
                en_description
                si_description
                ta_description
                en_summary
                si_summary
                ta_summary
                si_readmore_link
                en_readmore_link
                ta_readmore_link
                source_link
                en_verified
                si_verified
                ta_verified
                verification_method
                verification_notes
                verification_url
                rumour_original_text
                rumour_original_files {
                    fileName
                    url
                }
            }
        }
    `,
    getPublicPost: gql`
        query GetPublicPost($id: Int!) {
            getPublicPost(id: $id) {
                id
                type
                source_name
                timestamp
                last_update_at
                author_id
                author {
                    id
                    email
                    full_name
                    admin
                    editor
                }
                primary_rating
                secondary_rating
                en_title
                si_title
                ta_title
                featured_image_url
                featured_image_filename
                en_description
                si_description
                ta_description
                en_summary
                si_summary
                ta_summary
                si_readmore_link
                en_readmore_link
                ta_readmore_link
                source_link
                en_verified
                si_verified
                ta_verified
                verification_method
                verification_notes
                verification_url
                rumour_original_text
                rumour_original_files {
                    fileName
                    url
                }
            }
        }
    `,
    getPosts: gql`
        query Post($page: Int) {
            getPosts(page: $page) {
                count
                posts {
                    id
                    type
                    source_name
                    timestamp
                    last_update_at
                    author_id
                    author {
                        id
                        email
                        full_name
                        admin
                        editor
                    }
                    primary_rating
                    secondary_rating
                    en_title
                    si_title
                    ta_title
                    featured_image_url
                    featured_image_filename
                    en_description
                    si_description
                    ta_description
                    en_summary
                    si_summary
                    ta_summary
                    si_readmore_link
                    en_readmore_link
                    ta_readmore_link
                    source_link
                    en_verified
                    si_verified
                    ta_verified
                    verification_method
                    verification_notes
                    verification_url
                    rumour_original_text
                    rumour_original_files {
                        fileName
                        url
                    }
                }
            }
        }
    `,
    getPublicPosts: gql`
        query PublicPost($page: Int, $type: String) {
            getPublicPosts(page: $page, type: $type) {
                count
                nextPage
                posts {
                    id
                    type
                    source_name
                    timestamp
                    last_update_at
                    author_id
                    author {
                        id
                        email
                        full_name
                        admin
                        editor
                    }
                    primary_rating
                    secondary_rating
                    en_title
                    si_title
                    ta_title
                    featured_image_url
                    featured_image_filename
                    en_description
                    si_description
                    ta_description
                    en_summary
                    si_summary
                    ta_summary
                    si_readmore_link
                    en_readmore_link
                    ta_readmore_link
                    source_link
                    en_verified
                    si_verified
                    ta_verified
                    verification_method
                    verification_notes
                    verification_url
                    rumour_original_text
                    rumour_original_files {
                        fileName
                        url
                    }
                }
            }
        }
    `,
    createPost: gql`
        mutation CreatePost($data: PostInput!) {
            createPost(data: $data) {
                id
            }
        }
    `,
    updatePost: gql`
        mutation UpdatePost($data: UpdatePostInput!) {
            updatePost(data: $data) {
                id
            }
        }
    `,
    deletePost: gql`
        mutation DeletePost($data: DeletePostInput!) {
            deletePost(data: $data)
        }
    `,
}