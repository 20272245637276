import { FunctionComponent, ReactNode } from "react"
import { Button } from "antd";
import { CloseCircleFilled } from "@ant-design/icons";

interface ClosableTagProps{
    children: ReactNode;
    onClose: () => void;
}

export const ClosableTag: FunctionComponent<ClosableTagProps> = (props: ClosableTagProps) => {
    const { children, onClose } = props;

    return (
        <div style={{
            backgroundColor: "#F8F8F8",
            borderRadius: 6,
            border: "1px solid #C4C4C4",
            padding: "0.1rem 0.6rem",
            marginRight: "0.2rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            {children}
            <Button 
                type="link" 
                style={{
                    paddingRight: "0.1rem",
                    paddingLeft: "0.1rem",
                    marginLeft: "0.2rem",
                }}
                onMouseDown={() => {
                    onClose();
                }}
            ><CloseCircleFilled /></Button>
        </div>
    )
}