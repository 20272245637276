import React, { FunctionComponent, useEffect } from "react";

// containers
import { Layout } from "../../Common/containers/Layout";

// stores
import { tokenStore } from "../../Common/stores/token";

export const SignOut: FunctionComponent = () => {
    const resetToken = tokenStore().resetToken;

    useEffect(() => {
        resetToken();
    });

    return (
        <Layout>
            
        </Layout>
    )
}