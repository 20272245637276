import react, { FunctionComponent } from "react";
import { Formik, FormikErrors } from "formik";
import { Form, Input, Select, Row, Col, Checkbox, Button, Collapse, Tag } from "antd";

// types
import { PrimaryRating, PostInput, Post } from "../../shared";

// containers
import FileUpload from "../../Common/containers/FileUpload";
import ImageUpload from "../../Common/containers/ImageUpload";

// partials
import { ClosableTag } from "../../Common/partials/ClosableTag";

// helpers
import { getPrimaryRatingColor } from "../../Common/helpers/colors";

const { Option } = Select;
const { Panel } = Collapse;

const SUMMARY_CHARACTER_LIMIT = 300;

const PostTypeOptions = [
    {
        label: "Factcheck",
        value: "FACT_CHECK",
    },
    {
        label: "Longform",
        value: "LONGFORM"
    },
    {
        label: "Projects",
        value: "PROJECTS"
    },
];

const PrimaryRatingOptions = [
    {
        label: "Blue",
        value: "BLUE",
    },
    {
        label: "Yellow",
        value: "YELLOW"
    },
    {
        label: "Red",
        value: "RED"
    },
];

const SecondaryRatingOptions = [
    {
        label: "Verified",
        value: "VERIFIED",
    },
    {
        label: "Sensationalized",
        value: "SENSATIONALIZED"
    },
    {
        label: "Outdated",
        value: "OUTDATED"
    },
    {
        label: "Misattributed",
        value: "MISATTRIBUTED"
    },
    {
        label: "Satire",
        value: "SATIRE"
    },
    {
        label: "Mostly False",
        value: "MOSTLY_FALSE"
    },
    {
        label: "Misleading",
        value: "MISLEADING"
    },
    {
        label: "Scam",
        value: "SCAM"
    },
    {
        label: "False",
        value: "FALSE"
    },
    {
        label: "Caution Advised",
        value: "CAUTION_ADVISED"
    },
];

const VerificationMethodOptions = [
    {
        label: "Crosscheck",
        value: "CROSSCHECK",
    },
    {
        label: "Custom",
        value: "CUSTOM"
    },
];

const urlRegex = new RegExp(/^(http|https):\/\/.+\..+/i);

interface SinglePostProps{
    post?: Post;
    submitButtonText: string;
    submitButtonLoading: boolean;
    onSubmit: (post: PostInput) => Promise<void>;
}

export const SinglePost: FunctionComponent<SinglePostProps> = (props: SinglePostProps) => {
    const { post, submitButtonText, submitButtonLoading, onSubmit } = props;

    const initialValues = post ? post as PostInput : {
        type: "FACT_CHECK",
        primary_rating: "BLUE",
        secondary_rating: ["VERIFIED"],
        rumour_original_files: [] as { fileName: string, url: string }[],
        en_verified: false,
        si_verified: false,
        ta_verified: false,
    } as PostInput;

    const validate = (values: PostInput): FormikErrors<PostInput> => {
        const errors: FormikErrors<PostInput> = {};
        
        if (values.type === "FACT_CHECK") {
            if (!values.primary_rating) errors.primary_rating = "Primary rating is required";
            if (!values.secondary_rating || values.secondary_rating.length === 0) errors.secondary_rating = "Secondary rating is required";
            if (!values.source_name) errors.source_name = "Source name is required";
            if (!values.source_link) errors.source_link = "Source link is required";
            if (values.source_link && !values.source_link.match(urlRegex)) errors.source_link = "Source link has to be a link starting with http:// or https://";
            if (!values.verification_method) errors.verification_method = "Verification Method is required";

            if (values.verification_method === "CROSSCHECK" && (!values.verification_url || values.verification_url.length === 0)) 
                errors.verification_url = "Verification URL is required for crosschecked fact-checks";

            if (values.verification_method === "CUSTOM" && (!values.verification_notes || values.verification_notes.length === 0))
                errors.verification_notes = "Please enter your verificaiton notes";
        }

        // English
        if (!values.en_title) errors.en_title = "English Title is required";
        if (!values.en_description) errors.en_description = "English Description is required";
        if (values.en_description && values.en_description.length > SUMMARY_CHARACTER_LIMIT) {
            if (!values.en_summary) errors.en_summary = "English Summary is required if description is too long";
            if (values.en_summary && values.en_summary.length > SUMMARY_CHARACTER_LIMIT) errors.en_summary = `English Summary should have less that ${SUMMARY_CHARACTER_LIMIT} limits`;
        }

        if (values.type !== "FACT_CHECK") {
            if (!values.en_readmore_link) errors.en_readmore_link = "English Read More Link is required";
            if (values.en_readmore_link && !values.en_readmore_link.match(urlRegex)) {
                errors.en_readmore_link = "English Read More Link has to be a link starting with http:// or https://";
            }


            if (values.si_verified) {
                if (!values.si_readmore_link) errors.si_readmore_link = "Sinhala Read More Link is required";
                if (values.si_readmore_link && !values.si_readmore_link.match(urlRegex)) {
                    errors.si_readmore_link = "Sinhala Read More Link has to be a link starting with http:// or https://";
                }
            }

            if (values.ta_verified) {
                if (!values.ta_readmore_link) errors.ta_readmore_link = "Tamil Read More Link is required";
                if (values.ta_readmore_link && !values.ta_readmore_link.match(urlRegex)) {
                    errors.ta_readmore_link = "Tamil Read More Link has to be a link starting with http:// or https://";
                }
            }
        }

        // Sinhala
        if (values.si_verified && (!values.si_title)) errors.si_title = "Sinhala Title required if verified";
        if (values.si_verified && (!values.si_description)) errors.si_description = "Sinhala Description required if verified";
        if (values.si_description && values.si_description.length > SUMMARY_CHARACTER_LIMIT) {
            if (!values.si_summary) errors.si_summary = "Sinhala Summary is required if description is too long";
            if (values.si_summary && values.si_summary.length > SUMMARY_CHARACTER_LIMIT) errors.si_summary = `Sinhala Summary should have less that ${SUMMARY_CHARACTER_LIMIT} limits`;
        }

        // Tamil
        if (values.ta_verified && (!values.ta_title)) errors.ta_title = "Tamil Title required if verified";
        if (values.ta_verified && (!values.ta_description)) errors.ta_description = "Tamil Description required if verified";
        if (values.ta_description && values.ta_description.length > SUMMARY_CHARACTER_LIMIT) {
            if (!values.ta_summary) errors.ta_summary = "Tamil Summary is required if description is too long";
            if (values.ta_summary && values.ta_summary.length > SUMMARY_CHARACTER_LIMIT) errors.ta_summary = `Tamil Summary should have less that ${SUMMARY_CHARACTER_LIMIT} limits`;
        }

        return errors;
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    let rumourFiles = [];

                    for (let file of values.rumour_original_files) {
                        rumourFiles.push({
                            url: file.url,
                            fileName: file.fileName,
                        });
                    }

                    onSubmit({
                        ...values,
                        rumour_original_files: rumourFiles,
                    });
                }}
                validate={validate}
            >
                {({ values, errors, handleChange, setFieldValue, submitForm}) => (
                    <Form
                        layout="vertical"
                        colon={false}
                        onFinish={submitForm}
                    >
                        <Collapse defaultActiveKey={["metadata", "rumour", "sources", "rating"]} expandIconPosition="right">
                            <Panel header="Metadata" key={"metadata"}>
                                <Form.Item 
                                    label="Post Type"
                                    validateStatus={errors && errors.type ? "error" : undefined}
                                    help={errors.type}
                                >
                                    <Select 
                                        onChange={(value) => setFieldValue("type", value)}
                                        value={values.type}
                                    >
                                        {PostTypeOptions.map((type, i) => (
                                            <Option value={type.value} key={i}>{type.label}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                {values.type !== "FACT_CHECK" ? (
                                    <Form.Item label="Featured Image">
                                        <ImageUpload 
                                            width={1000}
                                            height={500}
                                            folder="uploads"
                                            onFileUpload={(fileUrl, fileName) => {
                                                setFieldValue("featured_image_filename", fileName);
                                                setFieldValue("featured_image_url", fileUrl);
                                            }}
                                        />
                                        {values.featured_image_filename && values.featured_image_url ? (
                                            <div style={{ display: "flex", flexDirection: "row", padding: "0.4rem 0rem" }}>
                                                <ClosableTag onClose={() => {
                                                    setFieldValue("featured_image_filename", null);
                                                    setFieldValue("featured_image_url", null);
                                                }}>
                                                    <a href={values.featured_image_url} target="_blank">{values.featured_image_filename}</a>
                                                </ClosableTag>
                                            </div>
                                        ) : null}
                                    </Form.Item>
                                ) : null}
                            </Panel>
                            {values.type === "FACT_CHECK" ? (
                                <Panel header="Rumour" key={"rumour"}>
                                    <Form.Item label="Rumour Original Text">
                                        <Input.TextArea 
                                            value={values.rumour_original_text} 
                                            name="rumour_original_text" 
                                            onChange={handleChange}
                                            rows={4} 
                                        />
                                    </Form.Item>
                                    <Form.Item label="Rumour Original Files">
                                        {values.rumour_original_files ? (
                                            <div style={{ display: "flex", flexDirection: "row", padding: "0.4rem 0rem" }}>
                                                {values.rumour_original_files ? values.rumour_original_files.map((file, i) => (
                                                    <ClosableTag key={i} onClose={() => {
                                                        let files = [
                                                            ...values.rumour_original_files.slice(0, i),
                                                            ...values.rumour_original_files.slice(i + 1, values.rumour_original_files.length),
                                                        ];
                                                        setFieldValue("rumour_original_files", files);
                                                    }}>
                                                        <a href={file.url} target="_blank">{file.fileName}</a>
                                                    </ClosableTag>
                                                )): null}
                                            </div>
                                        ) : null}
                                        <FileUpload 
                                            folder="uploads"
                                            placeholder="You can upload multiple files"
                                            buttonType="default"
                                            onFileUpload={(fileUrl, fileName) => {
                                                let newFiles: { fileName: string, url: string }[] = [];

                                                if (values.rumour_original_files) newFiles = values.rumour_original_files;

                                                if (fileName && fileUrl) {
                                                    newFiles = [...newFiles, {
                                                        fileName,
                                                        url: fileUrl,
                                                    }];
                                                }
                                                console.log(newFiles);
                                                setFieldValue("rumour_original_files", newFiles);
                                            }}
                                        />
                                    </Form.Item>
                                </Panel>
                            ) : null}
                            {values.type === "FACT_CHECK" ? (
                                <Panel header="Rating" key={"rating"}>
                                    <Row gutter={16}>
                                        <Col span={8}>
                                            <Form.Item 
                                                label="Primary Rating"
                                                validateStatus={errors && errors.primary_rating ? "error" : undefined}
                                                help={errors.primary_rating}
                                            >
                                                <Select 
                                                    onChange={(value) => {
                                                        setFieldValue("primary_rating", value);
                                                        if (value === "BLUE") setFieldValue("secondary_rating", ["VERIFIED"]);
                                                        else {
                                                            const filtered = values.secondary_rating.filter((option) => option !== "VERIFIED");

                                                            if (filtered.length > 0) setFieldValue("secondary_rating", filtered);
                                                            else setFieldValue("secondary_rating", ["FALSE"]);
                                                        }
                                                    }}
                                                    value={values.primary_rating}
                                                >
                                                    {PrimaryRatingOptions.map((type, i) => (
                                                        <Option value={type.value} key={i} style={{ display: "flex", alignItems: "center" }}>
                                                            <span style={{ 
                                                                display: "inline-block", 
                                                                width: 16, 
                                                                height: 16, 
                                                                backgroundColor: getPrimaryRatingColor(type.value as PrimaryRating), 
                                                                borderRadius: 4,
                                                                marginRight: 8,
                                                            }}></span>{type.label}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <Form.Item 
                                                label="Secondary Rating"
                                                validateStatus={errors && errors.secondary_rating ? "error" : undefined}
                                                help={errors.secondary_rating}
                                            >
                                                <Select 
                                                    mode="multiple"
                                                    onChange={(value) => setFieldValue("secondary_rating", value)}
                                                    value={values.secondary_rating}
                                                >
                                                    {SecondaryRatingOptions.map((type, i) => (
                                                        <Option 
                                                            value={type.value} 
                                                            key={i}
                                                            disabled={
                                                                (values.primary_rating === "BLUE" && type.value !== "VERIFIED") ||
                                                                (values.primary_rating !== "BLUE" && type.value === "VERIFIED")
                                                            }
                                                        >{type.label}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Panel>
                            ) : null}
                            {values.type === "FACT_CHECK" ? (
                                <Panel header="Sources and Verification" key={"sources"}>
                                    <Form.Item 
                                        label="Source Name"
                                        validateStatus={errors && errors.source_name ? "error" : undefined}
                                        help={errors.source_name}
                                    >
                                        <Input type="text" name="source_name" value={values.source_name} onChange={handleChange} />
                                    </Form.Item>
                                    <Form.Item 
                                        label="Source Link"
                                        validateStatus={errors && errors.source_link ? "error" : undefined}
                                        help={errors.source_link}
                                    >
                                        <Input type="text"  name="source_link" value={values.source_link} onChange={handleChange} />
                                    </Form.Item>
                                    <Form.Item 
                                        label="Verification Method"
                                        validateStatus={errors && errors.verification_method ? "error" : undefined}
                                        help={errors.verification_method}
                                    >
                                        <Select 
                                            onChange={(value) => {
                                                setFieldValue("verification_method", value);
                                            }}
                                            value={values.verification_method}
                                        >
                                            {VerificationMethodOptions.map((option, i) => (
                                                <Option value={option.value} key={i}>{option.label}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    {values.verification_method === "CROSSCHECK" ? (
                                        <Form.Item 
                                            label="Verification URL"
                                            validateStatus={errors && errors.verification_url ? "error" : undefined}
                                            help={errors.verification_url}
                                        >
                                            <Input type="text"  name="verification_url" value={values.verification_url} onChange={handleChange} />
                                        </Form.Item>
                                    ) : null}

                                    {values.verification_method === "CUSTOM" ? (
                                        <Form.Item 
                                            label="Verification Notes"
                                            validateStatus={errors && errors.verification_notes ? "error" : undefined}
                                            help={errors.verification_notes}
                                        >
                                            <Input.TextArea name="verification_notes" value={values.verification_notes} onChange={handleChange} />
                                        </Form.Item>
                                    ) : null}
                                </Panel>
                            ) : null}
                        </Collapse>
                        <h3 style={{ paddingLeft: "1rem", paddingTop: "1rem", paddingBottom: "0.4rem" }}>Post Content</h3>
                        <Collapse defaultActiveKey={["english", "sinhala", "tamil"]} expandIconPosition="right">
                            <Panel header="English" key={"english"}>
                                <Form.Item 
                                    label="English Title"
                                    validateStatus={errors && errors.en_title ? "error" : undefined}
                                    help={errors.en_title}
                                >
                                    <Input type="text" name="en_title" value={values.en_title} onChange={handleChange} />
                                </Form.Item>
                                <Form.Item 
                                    label="English Description"
                                    validateStatus={errors && errors.en_description ? "error" : undefined}
                                    help={errors.en_description}
                                >
                                    <Input.TextArea rows={4} name="en_description" value={values.en_description} onChange={handleChange} />
                                </Form.Item>
                                {values.en_description && values.en_description.length > SUMMARY_CHARACTER_LIMIT ? (
                                    <Form.Item 
                                        label={<span>English Summary <span className="character-limit">({values.en_summary?.length} / {SUMMARY_CHARACTER_LIMIT} Characters</span>)</span>}
                                        validateStatus={errors && errors.en_summary ? "error" : undefined}
                                        help={errors.en_summary}
                                    >
                                        <Input.TextArea rows={4} name="en_summary" value={values.en_summary} onChange={handleChange} />
                                    </Form.Item>
                                ) : null}
                                {values.type !== "FACT_CHECK" ? (
                                    <Form.Item 
                                        label="English Read More Link"
                                        validateStatus={errors && errors.en_readmore_link ? "error" : undefined}
                                        help={errors.en_readmore_link}
                                    >
                                        <Input type="url" name="en_readmore_link" value={values.en_readmore_link} onChange={handleChange} />
                                    </Form.Item>
                                ) : null}
                                <Form.Item>
                                    <Checkbox
                                        checked={values.en_verified}
                                        onChange={(e) => {
                                            setFieldValue("en_verified", e.target.checked);
                                        }}
                                    >English Verified</Checkbox>
                                </Form.Item>
                            </Panel>
                            <Panel header="Sinhala" key={"sinhala"}>
                                <Form.Item 
                                    label="Sinhala Title"
                                    validateStatus={errors && errors.si_title ? "error" : undefined}
                                    help={errors.si_title}
                                >
                                    <Input type="text" name="si_title" value={values.si_title} onChange={handleChange} />
                                </Form.Item>
                                <Form.Item 
                                    label="Sinhala Description"
                                    validateStatus={errors && errors.si_description ? "error" : undefined}
                                    help={errors.si_description}
                                >
                                    <Input.TextArea rows={4} name="si_description" value={values.si_description} onChange={handleChange} />
                                </Form.Item>

                                {values.si_description && values.si_description.length > SUMMARY_CHARACTER_LIMIT ? (
                                    <Form.Item
                                        label={<span>Sinhala Summary <span className="character-limit">({values.si_summary?.length} / {SUMMARY_CHARACTER_LIMIT} Characters</span>)</span>}
                                        validateStatus={errors && errors.si_summary ? "error" : undefined}
                                        help={errors.si_summary}
                                    >
                                        <Input.TextArea rows={4} name="si_summary" value={values.si_summary} onChange={handleChange} />
                                    </Form.Item>
                                ): null}

                                {values.type !== "FACT_CHECK" ? (
                                    <Form.Item 
                                        label="Sinhala Read More Link"
                                        validateStatus={errors && errors.si_readmore_link ? "error" : undefined}
                                        help={errors.si_readmore_link}
                                    >
                                        <Input type="url" name="si_readmore_link" onChange={handleChange} />
                                    </Form.Item>
                                ): null}

                                <Form.Item>
                                    <Checkbox
                                        checked={values.si_verified}
                                        onChange={(e) => {
                                            setFieldValue("si_verified", e.target.checked);
                                        }}
                                    >Sinhala Verified</Checkbox>
                                </Form.Item>
                            </Panel>
                            <Panel header="Tamil" key={"tamil"}>
                                <Form.Item 
                                    label="Tamil Title"
                                    validateStatus={errors && errors.ta_title ? "error" : undefined}
                                    help={errors.ta_title}
                                >
                                    <Input type="text" name="ta_title" value={values.ta_title} onChange={handleChange} />
                                </Form.Item>
                                <Form.Item
                                    label="Tamil Description"
                                    validateStatus={errors && errors.ta_description ? "error" : undefined}
                                    help={errors.ta_description}
                                >
                                    <Input.TextArea rows={4} name="ta_description" value={values.ta_description} onChange={handleChange} />
                                </Form.Item>

                                {values.ta_description && values.ta_description.length > SUMMARY_CHARACTER_LIMIT ? (
                                    <Form.Item
                                        label={<span>Tamil Summary <span className="character-limit">({values.ta_summary?.length} / {SUMMARY_CHARACTER_LIMIT} Characters</span>)</span>}
                                        validateStatus={errors && errors.ta_summary ? "error" : undefined}
                                        help={errors.ta_summary}
                                    >
                                        <Input.TextArea rows={4} name="ta_summary" value={values.ta_summary} onChange={handleChange} />
                                    </Form.Item>
                                ) : null}

                                {values.type !== "FACT_CHECK" ? (
                                    <Form.Item 
                                        label="Tamil Read More Link"
                                        validateStatus={errors && errors.ta_readmore_link ? "error" : undefined}
                                        help={errors.ta_readmore_link}
                                    >
                                        <Input type="url" name="ta_readmore_link" value={values.ta_readmore_link} onChange={handleChange} />
                                    </Form.Item>
                                ) : null}
                                
                                <Form.Item>
                                    <Checkbox
                                        checked={values.ta_verified}
                                        onChange={(e) => {
                                            setFieldValue("ta_verified", e.target.checked);
                                        }}
                                    >Tamil Verified</Checkbox>
                                </Form.Item>
                            </Panel>
                        </Collapse>
                        <Form.Item style={{ padding: "1rem 1rem" }}>
                            <Button type="primary" htmlType="submit" loading={submitButtonLoading}>{submitButtonText}</Button>
                        </Form.Item>
                    </Form>
                )}
            </Formik>
        </div>
    )
}