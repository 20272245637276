import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Divider, Tag } from "antd";
import moment from "moment";

// types
import { Post, PrimaryRating } from "../../shared";

// helpers
import { getPostTypeLabel } from "../../Common/helpers/posts";
import { getPrimaryRatingColor } from "../../Common/helpers/colors";

// partials
import { NewLinePara } from "./NewLinePara";
import { CheckedTag } from "../../Common/partials/CheckableTag";

interface PostItemProps{
    post: Partial<Post>;
}

const rowStyles = {
    padding: "0.2rem 0rem",
}

const flexRowStyles = {
    display: "flex",
    padding: "1rem 0rem",
}

export const PostItem: FunctionComponent<PostItemProps> = (props: PostItemProps) => {
    const { post } = props;

    return (
        <div className="post">
            <div style={rowStyles}>
                <a href={post.source_link} target="_blank">{post.source_name}</a>
            </div>
            <div style={rowStyles}>
                <span style={{ color: "#B7B7B7"}}>{moment(post.timestamp).format("LLL")}</span>
            </div>

            <div style={{ padding: "0.6rem 0rem 0.6rem 0rem" }}>
            <div style={rowStyles}>
                <Tag>ID: {post.id}</Tag>
                {post.type ? <Tag>{getPostTypeLabel(post.type)}</Tag> : null}
                {post.type === "FACT_CHECK" && post.primary_rating && post.secondary_rating ? 
                    post.secondary_rating.map((rating, i) => (
                        <Tag key={i} color={getPrimaryRatingColor(post.primary_rating as PrimaryRating)}>{rating}</Tag>   
                    )
                ) : null}
            </div>
            </div>
            <Divider style={{ marginBottom: 0, marginTop: "0.4rem" }}/>
            <div style={flexRowStyles}>
                <CheckedTag 
                    label="E" 
                    color={getPrimaryRatingColor(post.primary_rating as PrimaryRating)} 
                    checked={post.en_verified} 
                />
                <div>
                    <Link to={{ hash: `#post=${post.id}`}}>
                        <h3>{post.en_title}</h3>
                    </Link>
                    <NewLinePara text={post.en_summary ? post.en_summary : post.en_description} />
                    <Divider style={{ marginBottom: 0 }}/>
                </div>
            </div>
            {post.si_title || post.si_description ? (
                <div style={flexRowStyles}>
                    <CheckedTag 
                        label="S" 
                        color={getPrimaryRatingColor(post.primary_rating as PrimaryRating)} 
                        checked={post.si_verified} 
                    />
                    <div>
                        <Link to={{ hash: `#post=${post.id}`}}>
                            <h3>{post.si_title}</h3>
                        </Link>
                        <NewLinePara text={post.si_summary ? post.si_summary : post.si_description} />
                        <Divider style={{ marginBottom: 0 }}/>
                    </div>
                </div>
            ) : null}
            {post.ta_title || post.ta_description ? (
                <div style={flexRowStyles}>
                    <CheckedTag 
                        label="T" 
                        color={getPrimaryRatingColor(post.primary_rating as PrimaryRating)}
                        checked={post.ta_verified} 
                    />
                    <div>
                        <Link to={{ hash: `#post=${post.id}`}}>
                            <h3>{post.ta_title}</h3>
                        </Link>
                        <NewLinePara text={post.ta_summary ? post.ta_summary : post.ta_description} />
                        <Divider style={{ marginBottom: 0 }}/>
                    </div>
                </div>
            ) : null}
        </div>
    );
}