import { PostType, PostVerificationMethod } from "../../shared";

export const getPostTypeLabel = (type: PostType): string => {
    if (type === "FACT_CHECK") return "Factcheck";
    if (type === "LONGFORM") return "Longform";
    if (type === "PROJECTS") return "Project";
    return "";
}

export const getPostVerificationMethod = (method?: PostVerificationMethod): string => {
    if (method === "CROSSCHECK") return "Crosschecked";
    if (method === "CUSTOM") return "Custom";
    return "";
}