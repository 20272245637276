import React, { FunctionComponent } from "react";
import { useQuery } from "@apollo/client";
import { Button, Pagination } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";

// queries
import { PostsQueries, Post } from "../../shared";

// containers
import { Layout } from "../../Common/containers/Layout";
import { PostModal } from "../containers/PostModal";

// partials
import { PostItem } from "../partials/PostItem";

interface GetPostsResults{
    getPosts:{
        posts: Partial<Post>[];
        count: number;
    }
}

export const AllPosts: FunctionComponent = () => {
    const { hash, search } = useLocation();
    const navigate = useNavigate();

    const params = new URLSearchParams(search);
    const pageStr = params.get("page");

    const page =  pageStr ? parseInt(pageStr) : 1;

    const { data, loading, refetch } = useQuery<GetPostsResults>(PostsQueries.getPosts, {
        variables: {
            page: page,
        }
    });


    const parsedHash = hash.replaceAll(/#/ig, "");
    const parsedArr = parsedHash.split("=");

    let postModalId: number | null = null;

    if (parsedArr.length >= 2) {
        postModalId = parseInt(parsedArr[1]);
    }

    return (
        <Layout>
            <div className="container">
                <div className="section-header">
                    <h2>All Posts</h2>
                    <Link to="/newPost"><Button type="primary"><PlusOutlined /> Create New</Button></Link>
                </div>
                {loading ? (<h3>Loading</h3>) : (
                    <>
                        {data?.getPosts.posts.map((post, i) => (
                            <PostItem post={post} key={i} />
                        ))}
                    </>
                )}
                <div style={{ padding: "1rem 0rem" }}>
                    <Pagination 
                        current={page}
                        pageSize={10} 
                        total={data?.getPosts.count} 
                        onChange={(page) => {
                            navigate({
                                search: `?page=${page}`
                            });
                        }}
                    />
                </div>
            </div>
            {postModalId ? (
                <PostModal 
                    postId={postModalId}
                    onClose={() => {
                        navigate({
                            hash: "",
                        });
                    }}
                    refetch={refetch}
                />
            ) : null}
        </Layout>
    )
}