import { DownloadOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Collapse,
  InputNumber,
  Layout,
  Row,
  Slider,
  Spin,
  Tabs,
} from "antd";
import { createRef, FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import { createFileName, useScreenshot } from "use-react-screenshot";
import { Post, PostsQueries } from "../../shared";
import { SocialMediaPost } from "../partials/SocialMediaPost";

const { TabPane } = Tabs;

export const SocialMediaPostPage: FunctionComponent = () => {
  const englishRef = createRef<HTMLDivElement>();
  const sinhalaRef = createRef<HTMLDivElement>();
  const tamilRef = createRef<HTMLDivElement>();
  const { postId: postIdStr } = useParams();

  const postIdInt = postIdStr ? parseInt(postIdStr) : null;

  const { data, loading } = useQuery<{ getPublicPost: Post }>(
    PostsQueries.getPublicPost,
    {
      variables: {
        id: postIdInt,
      },
    }
  );

  const [englishContentSize, setEnglishContentSize] = useState(18);
  const [sinhalaContentSize, setSinhalaContentSize] = useState(18);
  const [tamilContentSize, setTamilContentSize] = useState(15);

  const [englishImage, takeScreenShotEn] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const [sinhalaImage, takeScreenShotSi] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const [tamilImage, takeScreenShotTa] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0,
  });

  const download = (image: any, { name = "img", extension = "jpg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  return (
    <>
      <Layout>
        <div className="container" style={{ width: "100%", height: "100%" }}>
          <div className="section-header">
            <h2>Social Media Posters</h2>
          </div>
          <div
            style={{
              margin: "0 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tabs defaultActiveKey="1" centered>
              <TabPane tab="English" key="1">
                {!loading && (
                  <SocialMediaPost
                    ref={englishRef}
                    data={data?.getPublicPost}
                    lang={"en"}
                    contentFontSize={englishContentSize}
                  />
                )}

                {loading && (
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Spin />
                  </div>
                )}
                <div style={{ marginTop: 10 }}>
                  {!loading && (
                    <Collapse defaultActiveKey={["1"]}>
                      <Collapse.Panel header="Poster Options" key="1">
                        <div style={{ paddingTop: 20 }}>
                          <Row>
                            <div
                              style={{
                                marginLeft: 5,
                              }}
                            >
                              Content Font Size
                            </div>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <Slider
                                min={1}
                                max={20}
                                onChange={(value) => {
                                  setEnglishContentSize(value);
                                }}
                                value={
                                  typeof englishContentSize === "number"
                                    ? englishContentSize
                                    : 0
                                }
                              />
                            </Col>
                            <Col span={4}>
                              <InputNumber
                                min={1}
                                max={20}
                                style={{ margin: "0 16px" }}
                                value={englishContentSize}
                                onChange={(value) => {
                                  setEnglishContentSize(value);
                                }}
                              />
                            </Col>
                          </Row>
                          <Button
                            style={{ marginTop: 20, marginBottom: 20 }}
                            type="primary"
                            icon={<DownloadOutlined />}
                            size={"large"}
                            onClick={() => {
                              takeScreenShotEn(englishRef.current).then(
                                download(englishImage, {
                                  extension: "jpg",
                                  name: `${postIdInt}-english`,
                                })
                              );
                            }}
                          >
                            Download Poster in English
                          </Button>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  )}
                </div>
              </TabPane>
              <TabPane tab="Sinhala" key="2">
                {!loading && (
                  <SocialMediaPost
                    ref={sinhalaRef}
                    data={data?.getPublicPost}
                    lang={"si"}
                    contentFontSize={sinhalaContentSize}
                  />
                )}
                {loading && <Spin />}
                <div style={{ marginTop: 10 }}>
                  {!loading && (
                    <Collapse defaultActiveKey={["1"]}>
                      <Collapse.Panel header="Poster Options" key="1">
                        <div style={{ paddingTop: 20 }}>
                          <Row>
                            <div
                              style={{
                                marginLeft: 5,
                              }}
                            >
                              Content Font Size
                            </div>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <Slider
                                min={1}
                                max={20}
                                onChange={(value) => {
                                  setSinhalaContentSize(value);
                                }}
                                value={
                                  typeof sinhalaContentSize === "number"
                                    ? sinhalaContentSize
                                    : 0
                                }
                              />
                            </Col>
                            <Col span={4}>
                              <InputNumber
                                min={1}
                                max={20}
                                style={{ margin: "0 16px" }}
                                value={sinhalaContentSize}
                                onChange={(value) => {
                                  setSinhalaContentSize(value);
                                }}
                              />
                            </Col>
                          </Row>
                          <Button
                            style={{ marginTop: 20, marginBottom: 20 }}
                            type="primary"
                            icon={<DownloadOutlined />}
                            size={"large"}
                            onClick={() => {
                              takeScreenShotSi(sinhalaRef.current).then(
                                download(sinhalaImage, {
                                  extension: "jpg",
                                  name: `${postIdInt}-sinhala`,
                                })
                              );
                            }}
                          >
                            Download Poster in Tamil
                          </Button>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  )}
                </div>
              </TabPane>
              <TabPane tab="Tamil" key="3">
                {!loading && (
                  <SocialMediaPost
                    ref={tamilRef}
                    data={data?.getPublicPost}
                    lang={"ta"}
                    contentFontSize={tamilContentSize}
                  />
                )}
                {loading && <Spin />}
                <div style={{ marginTop: 10 }}>
                  {!loading && (
                    <Collapse defaultActiveKey={["1"]}>
                      <Collapse.Panel header="Poster Options" key="1">
                        <div style={{ paddingTop: 20 }}>
                          <Row>
                            <div
                              style={{
                                marginLeft: 5,
                              }}
                            >
                              Content Font Size
                            </div>
                          </Row>
                          <Row>
                            <Col span={12}>
                              <Slider
                                min={1}
                                max={20}
                                onChange={(value) => {
                                  setTamilContentSize(value);
                                }}
                                value={
                                  typeof tamilContentSize === "number"
                                    ? tamilContentSize
                                    : 0
                                }
                              />
                            </Col>
                            <Col span={4}>
                              <InputNumber
                                min={1}
                                max={20}
                                style={{ margin: "0 16px" }}
                                value={tamilContentSize}
                                onChange={(value) => {
                                  setTamilContentSize(value);
                                }}
                              />
                            </Col>
                          </Row>
                          <Button
                            style={{ marginTop: 20, marginBottom: 20 }}
                            type="primary"
                            icon={<DownloadOutlined />}
                            size={"large"}
                            onClick={() => {
                              takeScreenShotTa(tamilRef.current).then(
                                download(tamilImage, {
                                  extension: "jpg",
                                  name: `${postIdInt}-tamil`,
                                })
                              );
                            }}
                          >
                            Download Poster in Tamil
                          </Button>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  )}
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Layout>
    </>
  );
};
