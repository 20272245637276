import create,{ GetState, SetState } from "zustand";
import { persist, StoreApiWithPersist } from "zustand/middleware";

type Token = string | undefined;

type TokenStoreState = {
    token: Token;
    setToken: (token: Token) => void;
    resetToken: () => void;
}

export const tokenStore = create<TokenStoreState, SetState<TokenStoreState>, GetState<TokenStoreState>, StoreApiWithPersist<TokenStoreState> >(persist(
	(set) => ({
		token: undefined as Token,

		setToken: (token: Token) => {
			set({
				token,
			});
		},

		resetToken: () => {
			set({
				token: undefined,
			});
		},
	}),{ 
		name: "watchdog-cms-token-store",
	}
));