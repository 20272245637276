import React, { FunctionComponent, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import axios from "axios";
import ImgCrop from "antd-img-crop";
import {  Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const unsafeAndReservedCharRegex = /(\&|\$|\+|\,|\/|\:|\;|\=|\?|\@|\||\#|\ |\<|\>|\[|\]|\{|\}|\||\\|\^|\%|\~)/ig;

const generateFileName = (file: File) => {
	let timestamp = new Date().getTime();
	const tmpFileName = `${timestamp}_${file.name.replace(/ /ig, "")}`;
	const cleanedFileName = tmpFileName.replace(unsafeAndReservedCharRegex, '_');
	return cleanedFileName;
};

const Mutations = {
	getSignedUrl: gql`
    mutation GetSignedUrl($data: FileSignedURLInput!){
      getSignedUrl(data: $data) {
        signedRequest
        url
      }
    }
  `,
};


interface FileSignedURLInput{
	fileName: string;
	contentType: string;
	folder: string;
}

interface FileSignedURLResponse{
	getSignedUrl: {
		url: string;
		signedRequest: string;
	}
}

interface ImageUploadProps{
  width: number;
  height: number;
  folder: string;
  uploadButtonText?: string;

  onFileUpload?: (fileURL: string, fileName: string) => void;
}

const ImageUpload: FunctionComponent<ImageUploadProps> = (props: ImageUploadProps) => {
	const {
		width,
		height,
		folder,
		onFileUpload,
		uploadButtonText,
	} = props;
  
	const [ fileList, setFileList ] = useState<File[]>([]);

	const [ getSignedUrl ] = useMutation<FileSignedURLResponse, { data: FileSignedURLInput }>(Mutations.getSignedUrl);

	return (
		<ImgCrop aspect={width/height}>
			<Upload 
				style={{ display: "flex", justifyContent: "center" }}
				customRequest={async (e) => {
					const file = e.file as File;
					setFileList([ file ]);

					if (file) {
						try {
							const resp = await getSignedUrl({
								variables: {
									data: {
										fileName: generateFileName(file),
										contentType: file.type,
										folder: folder,
									}
								}
							});

                            if (!resp || !resp.data) throw new Error("Error uploading image");
              
							const uploadURL = resp.data.getSignedUrl.signedRequest;
							const fileURL = resp.data.getSignedUrl.url;
                
							var options = {
								headers: {
									"Content-Type": file.type
								}
							};
                
							try {
								await axios.put(uploadURL, file, options);
								//e.onSuccess({}, file);

								if (onFileUpload) onFileUpload(fileURL, file.name);
							} catch(uploadError){
								console.log(uploadError);
							}
                            
						} catch(e) {
							message.error("File upload error!");
						}
					}
				}}

				multiple={false} 
				fileList={[]}
				onRemove={() => {
					setFileList([]);
				}}
			>
				<Button type="primary" ghost>
					<UploadOutlined /> {uploadButtonText ? uploadButtonText : "Upload image"}
				</Button>
			</Upload>
		</ImgCrop>
	);
};

export default ImageUpload;