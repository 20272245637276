import react, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

// stores
import { tokenStore } from "../stores/token";

// assets
import logo from "../assets/logo.png";

const HeaderWithAuth: FunctionComponent = () => {
    return (
        <header style={{ position: "sticky", top: 0, zIndex: 100 }}>
            <div className="container">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Link to="">
                        <img src={logo} style={{ width: 140 }}/>
                    </Link>
                    <div>
                        <ul className="menu">
                            <li><Link to="/">Posts</Link></li>
                            <li><Link to="/">Accounts</Link></li>
                            <li>
                                <Link to="/sign-out">Sign Out</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}

const HeaderWithoutAuth: FunctionComponent = () => {
    return (
        <header style={{ position: "sticky", top: 0, zIndex: 100 }}>
            <div className="container">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Link to="">
                        <img src={logo} style={{ width: 140 }}/>
                    </Link>
                </div>
            </div>
        </header>
    );
}

export const Header: FunctionComponent = () => {
	const token = tokenStore((state) => state.token);

	if (token) return <HeaderWithAuth />;

	return <HeaderWithoutAuth />;
};