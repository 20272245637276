import react, { FunctionComponent } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";

// queries
import { PostsQueries } from "../../shared";

// partials
import { SinglePost } from "../partials/SinglePost";

export const CreatePost: FunctionComponent = () => {
    const [ createPost, { loading: creating }] = useMutation(PostsQueries.createPost);
    const { refetch } = useQuery(PostsQueries.getPosts, {
        variables: {
            page: 1,
        }
    });

    const navigate = useNavigate();

    return (
        <div className="container">
            <div className="section-header">
                <h2>Create Post</h2>
            </div>
            <SinglePost 
                onSubmit={async (values) => {
                    try {
                        const res = await createPost({
                            variables: {
                                data: values,
                            }
                        });
                        refetch();
                        
                        navigate({
                            pathname: "/",
                            hash: `#post=${res.data.createPost.id}`
                        });
                    } catch (e) {
                        console.log(e);
                    }
                }}
                submitButtonText="Create Post"
                submitButtonLoading={creating}
            />
        </div>
    );
}