import React, { FunctionComponent } from 'react';

interface CheckedTagProps{
    color?: string;
    checked?: boolean;
    label?: string;
}

export const CheckedTag: FunctionComponent<CheckedTagProps> = ({ color = "#1dbf98", label = "E", checked = false} : CheckedTagProps) =>{
    const style = {
        fontSize: '1.2em',
        padding: '0.1em 0.6em',
        marginRight: "1rem",
        display: 'inline-block',
        borderRadius: 4,
        cursor: 'pointer',
        border: `1px solid ${color}`,
        color: checked ? 'white' : color,
        backgroundColor: checked ? color : undefined,
    };

    return (
      <a><span style={style}>{label}</span></a>
    );
}