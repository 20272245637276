import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink, ApolloError } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

// helpers
import { handleTokenError, InternalGraphQLError } from "../Common/helpers/error";

import { tokenStore } from "../Common/stores/token";

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_API ? process.env.REACT_APP_GRAPHQL_API : "http://localhost:4000/graphql";

const httpLink = createHttpLink({
	uri: GRAPHQL_ENDPOINT,
});

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
        const tmp = [...graphQLErrors];
        handleTokenError(tmp as InternalGraphQLError[]);
    }
});

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = tokenStore.getState().token;
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : "",
		}
	};
});

export const graphQLClient = new ApolloClient({
	link: ApolloLink.from([
		errorLink,
		authLink,
		httpLink,
	]),
	cache: new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					me: {
						merge: true,
					}
				}
			}
		}
	}),
});

export const graphQLClientWithoutAuth = new ApolloClient({
	link: httpLink,
	cache: new InMemoryCache()
});