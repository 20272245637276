import { gql } from "@apollo/client";

export const AuthMutations = {
	signIn: gql`
        mutation SignIn($data: SignInInput!) {
            signIn(data: $data) {
                token
            }
        }
    `,
}
