import { Account } from "./account";

export type Language = "en" | "si" | "ta";

export type TestLerna = "Testing";

export enum PostType {
    FACT_CHECK = "FACT_CHECK",
    LONGFORM = "LONGFORM",
    PROJECTS = "PROJECTS",
}

export enum PrimaryRating {
    BLUE = "BLUE",
    YELLOW = "YELLOW",
    RED = "RED",
}

export enum SecondaryRating {
    VERIFIED = "VERIFIED",
    SENSATIONALIZED = "SENSATIONALIZED",
    OUTDATED = "OUTDATED",
    MISATTRIBUTED = "MISATTRIBUTED",
    SATIRE = "SATIRE",
    MOSTLY_FALSE = "MOSTLY_FALSE",
    MISLEADING = "MISLEADING",
    SCAM = "SCAM",
    FALSE = "FALSE",
    CAUTION_ADVISED = "CAUTION_ADVISED",
}

export enum PostVerificationMethod {
    CROSSCHECK = "CROSSCHECK",
    CUSTOM = "CUSTOM",
}

export interface Post{
    id: number;
    timestamp: Date;
    last_update_at: Date;
    type: PostType;

    author_id: number;
    author?: Account;

    primary_rating: PrimaryRating;
    secondary_rating: SecondaryRating[];

    en_title: string;
    si_title?: string;
    ta_title?: string;

    featured_image_url?: string;
    featured_image_filename?: string;

    en_description?: string;
    si_description?: string;
    ta_description?: string;

    en_summary?: string;
    si_summary?: string;
    ta_summary?: string;

    si_readmore_link?: string;
    en_readmore_link?: string;
    ta_readmore_link?: string;

    source_name?: string;
    source_link?: string;

    en_verified: boolean;
    si_verified: boolean;
    ta_verified: boolean;

    verification_method?: PostVerificationMethod;
    verification_notes?: string;
    verification_url?: string;

    rumour_original_text: string;
    rumour_original_files: { fileName: string, url: string }[];
}

export type PostInput = Omit<Post, "id" | "timestamp" | "last_update_at" | "author_id" | "author">;

export interface UpdatePostInput {
    id: number;
    post: PostInput;
}

export interface DeletePostInput {
    id: number;
}