import React, { FunctionComponent } from "react";

interface NewLineParaProps{
    text?: string;
}

export const NewLinePara: FunctionComponent<NewLineParaProps> = ({ text }: NewLineParaProps) => {
    if (!text) return null;
    
    return (
        <div>
            {text.split("\n").map((para, i) => (
                <p key={i}>{para}</p>
            ))}
        </div>
    )
}