import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

// stores
import { tokenStore } from "./Common/stores/token";


async function main(){
	// This fixes zustand persit bug where there's a delay in reading from local storage
	// This delay was causing AuthLayouts to break since the first render wouldn't have user or tokens in the AuthStore
	const tokenFromStorage = localStorage.getItem("watchdog-cms-token-store");

	if (tokenFromStorage) {
		const parsed = JSON.parse(tokenFromStorage);
		if (parsed && parsed.state && parsed.state.token) {
			tokenStore.setState({ token: parsed.state.token });
		}
	}

	ReactDOM.render(<App />, document.getElementById("root"));
}

main();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
