export interface InternalGraphQLError{
    extensions: {
      code?: string;
      exception?: {
        code?: string;
        message: string;
        name: string;
      }
    }
  }
  
  export const handleTokenError = (errors: InternalGraphQLError[]): void => {
      for (let error of errors) {
          if (error.extensions.exception && error.extensions.exception.code === "INVALID_AUTH_TOKEN") {
              window.location.replace("/sign-out");
              break;
          }
  
          if (error.extensions && error.extensions.code === "EXPIRED_AUTH_TOKEN") {
              window.location.replace("/sign-out");
              break;
          }
  
          if (error.extensions.exception && error.extensions.exception.name === "TokenExpiredError") {
              window.location.replace("/sign-out");
              break;
          }
      }
  };