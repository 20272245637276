import React, { FunctionComponent, ReactNode, useEffect } from "react";
import {
    Navigate
} from "react-router-dom";

// store
import { tokenStore } from "../stores/token";

interface AuthLayoutProps{
  children: ReactNode;
}

export const AuthLayout: FunctionComponent<AuthLayoutProps> = (props: AuthLayoutProps) => {
	const {
		children,
	} = props;

	const token = tokenStore((state) => state.token);

	if (token) {
		return <Navigate to="/" />;
	}
  
	return (
		<div className="app-area">
			<div className="main-area">
				{children}
			</div>
		</div>
	);
};