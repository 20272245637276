import { CheckCircleTwoTone } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { Button, Descriptions, Modal, Tabs, Tag } from "antd";
import moment from "moment";
import { FunctionComponent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { getPrimaryRatingColor } from "../../Common/helpers/colors";
import {
  getPostTypeLabel,
  getPostVerificationMethod,
} from "../../Common/helpers/posts";
import { Post, PostsQueries } from "../../shared";

// partials
import { NewLinePara } from "../partials/NewLinePara";

const { TabPane } = Tabs;
const { confirm } = Modal;

interface PostModalProps {
  postId: number;
  onClose: () => void;
  refetch?: () => void;
}

export const PostModal: FunctionComponent<PostModalProps> = (
  props: PostModalProps
) => {
  const { postId, onClose, refetch } = props;
  const [activeTab, setActiveTab] = useState("en");

  const navigate = useNavigate();

  const { data, loading } = useQuery<{ getPost: Post }>(PostsQueries.getPost, {
    variables: {
      id: postId,
    },
  });

  const [deletePost, { loading: deleting }] = useMutation(
    PostsQueries.deletePost
  );

  if (loading || !data) return <h2>Loading</h2>;

  return (
    <Modal
      title={data.getPost.en_title}
      width={600}
      visible={true}
      footer={null}
      onCancel={() => {
        onClose();
      }}
    >
      <div style={{ paddingBottom: "1rem" }}>
        <div style={{ marginBottom: "1rem" }}>
          <Link to={`/post/${postId}/edit`}>
            <Button type="primary" style={{ marginRight: 8 }}>
              Edit
            </Button>
          </Link>
          <Button
            type="primary"
            danger
            onClick={async () => {
              confirm({
                title: "Are you sure you want to delete this post?",
                okText: "Yes",
                okButtonProps: {
                  danger: true,
                  loading: deleting,
                },
                cancelText: "No",
                onOk: async () => {
                  try {
                    await deletePost({
                      variables: {
                        data: {
                          id: postId,
                        },
                      },
                    });

                    if (refetch) refetch();

                    navigate({
                      pathname: "/",
                    });
                  } catch (e) {
                    console.log(e);
                  }
                },
              });
            }}
          >
            Delete
          </Button>
        </div>
        {data.getPost.featured_image_url ? (
          <div
            style={{
              marginBottom: "1rem",
              borderRadius: 8,
              overflow: "hidden",
            }}
          >
            <img
              style={{
                width: "100%",
              }}
              src={data.getPost.featured_image_url}
            />
          </div>
        ) : null}
        <Descriptions column={3} layout="vertical" colon={false}>
          <Descriptions.Item label="Post Type" span={1}>
            <Tag>{getPostTypeLabel(data.getPost.type)}</Tag>
          </Descriptions.Item>
          {data.getPost.type === "FACT_CHECK" ? (
            <Descriptions.Item label="Ratings" span={2}>
              {data.getPost.type === "FACT_CHECK" ? (
                <>
                  {data.getPost.secondary_rating.map((rating, i) => (
                    <Tag
                      key={i}
                      color={getPrimaryRatingColor(data.getPost.primary_rating)}
                    >
                      {rating}
                    </Tag>
                  ))}
                </>
              ) : null}
            </Descriptions.Item>
          ) : null}
          <>
            <Descriptions.Item label="Created At">
              {moment(data.getPost.timestamp).format("LLL")}
            </Descriptions.Item>
            <Descriptions.Item label="Last update at" span={2}>
              {moment(data.getPost.last_update_at).format("LLL")}
            </Descriptions.Item>
          </>

          {data.getPost.type === "FACT_CHECK" ? (
            <>
              <Descriptions.Item label="Media Posters" span={3}>
                <Link to={`/post/${postId}/social-media`} target={"_blank"}>
                  View Media Posters
                </Link>
              </Descriptions.Item>
            </>
          ) : null}

          {data.getPost.type === "FACT_CHECK" ? (
            <>
              <Descriptions.Item label="Source Name">
                {data.getPost.source_name}
              </Descriptions.Item>
              <Descriptions.Item label="Source Link">
                <a href={data.getPost.source_link} target="_blank">
                  {data.getPost.source_link}
                </a>
              </Descriptions.Item>
            </>
          ) : null}
        </Descriptions>
      </div>
      <Tabs
        activeKey={activeTab}
        onChange={(activeKey) => {
          setActiveTab(activeKey);
        }}
      >
        {data.getPost.type === "FACT_CHECK" ? (
          <TabPane tab="Rumour and Verification" key="rumour">
            <Descriptions column={1} layout="vertical" colon={false}>
              <Descriptions.Item label="Original Rumour Text">
                {data.getPost.rumour_original_text ? (
                  <NewLinePara text={data.getPost.rumour_original_text} />
                ) : (
                  "-"
                )}
              </Descriptions.Item>
              {data.getPost.rumour_original_files &&
              data.getPost.rumour_original_files.length > 0 ? (
                <Descriptions.Item label="Original Rumour Files">
                  {data.getPost.rumour_original_files.map((file, i) => (
                    <Tag>
                      <a href={file.url} target="_blank">
                        {file.fileName}
                      </a>
                    </Tag>
                  ))}
                </Descriptions.Item>
              ) : null}
              <Descriptions.Item label="Verification Method">
                {getPostVerificationMethod(data.getPost.verification_method)}
              </Descriptions.Item>
              {data.getPost.verification_method === "CROSSCHECK" ? (
                <Descriptions.Item label="Verification Link">
                  <a href={data.getPost.verification_url}>
                    {data.getPost.verification_url}
                  </a>
                </Descriptions.Item>
              ) : null}
              {data.getPost.verification_method === "CUSTOM" ? (
                <Descriptions.Item label="Verification Notes">
                  {data.getPost.verification_notes ? (
                    <NewLinePara text={data.getPost.verification_notes} />
                  ) : (
                    "-"
                  )}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          </TabPane>
        ) : null}
        <TabPane tab="English" key="en">
          <Descriptions column={1} layout="vertical" colon={false}>
            <Descriptions.Item
              label={
                <span>
                  Title{" "}
                  {data.getPost.en_verified ? <CheckCircleTwoTone /> : null}
                </span>
              }
            >
              {data.getPost.en_title ? data.getPost.en_title : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              {data.getPost.en_description ? (
                <NewLinePara text={data.getPost.en_description} />
              ) : (
                "-"
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Summary">
              {data.getPost.en_summary ? (
                <NewLinePara text={data.getPost.en_summary} />
              ) : (
                "-"
              )}
            </Descriptions.Item>
            {data.getPost.type !== "FACT_CHECK" ? (
              <Descriptions.Item label="Read More">
                <a href={data.getPost.en_readmore_link} target="_blank">
                  {data.getPost.en_readmore_link
                    ? data.getPost.en_readmore_link
                    : "-"}
                </a>
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        </TabPane>
        <TabPane tab="Sinhala" key="si">
          <Descriptions column={1} layout="vertical" colon={false}>
            <Descriptions.Item
              label={
                <span>
                  Title{" "}
                  {data.getPost.si_verified ? <CheckCircleTwoTone /> : null}
                </span>
              }
            >
              {data.getPost.si_title ? data.getPost.si_title : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              {data.getPost.si_description ? (
                <NewLinePara text={data.getPost.si_description} />
              ) : (
                "-"
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Summary">
              {data.getPost.si_summary ? (
                <NewLinePara text={data.getPost.si_summary} />
              ) : (
                "-"
              )}
            </Descriptions.Item>
            {data.getPost.type !== "FACT_CHECK" ? (
              <Descriptions.Item label="Read More">
                <a href={data.getPost.si_readmore_link} target="_blank">
                  {data.getPost.si_readmore_link
                    ? data.getPost.si_readmore_link
                    : "-"}
                </a>
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        </TabPane>
        <TabPane tab="Tamil" key="ta">
          <Descriptions column={1} layout="vertical" colon={false}>
            <Descriptions.Item
              label={
                <span>
                  Title{" "}
                  {data.getPost.ta_verified ? <CheckCircleTwoTone /> : null}
                </span>
              }
            >
              {data.getPost.ta_title ? data.getPost.ta_title : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Description">
              {data.getPost.ta_description ? (
                <NewLinePara text={data.getPost.ta_description} />
              ) : (
                "-"
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Summary">
              {data.getPost.ta_summary ? (
                <NewLinePara text={data.getPost.ta_summary} />
              ) : (
                "-"
              )}
            </Descriptions.Item>
            {data.getPost.type !== "FACT_CHECK" ? (
              <Descriptions.Item label="Read More">
                <a href={data.getPost.ta_readmore_link} target="_blank">
                  {data.getPost.ta_readmore_link
                    ? data.getPost.ta_readmore_link
                    : "-"}
                </a>
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        </TabPane>
      </Tabs>
    </Modal>
  );
};
