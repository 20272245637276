import { Tag } from "antd";
import { forwardRef, FunctionComponent } from "react";
import WatchdogLogo from "../../shared/assets/watchdog_full_logo.svg";

// Ratings
import { SecondaryRating } from "../../shared";
import RatingCaution from "../../shared/assets/ratings/RatingCaution.png";
import RatingFalse from "../../shared/assets/ratings/RatingFalse.png";
import RatingOutdated from "../../shared/assets/ratings/RatingOutdated.png";
import RatingSatire from "../../shared/assets/ratings/RatingSatire.png";
import RatingScam from "../../shared/assets/ratings/RatingScam.png";
import RatingSensationalized from "../../shared/assets/ratings/RatingSensationalized.png";
import RatingTrue from "../../shared/assets/ratings/RatingTrue.png";

const getIcon = (rating: SecondaryRating) => {
  if (
    rating === SecondaryRating.FALSE ||
    rating === SecondaryRating.MISLEADING ||
    rating === SecondaryRating.MOSTLY_FALSE ||
    rating === SecondaryRating.MISATTRIBUTED
  ) {
    return RatingFalse;
  }
  if (rating === SecondaryRating.SENSATIONALIZED) return RatingSensationalized;
  if (rating === SecondaryRating.SATIRE) return RatingSatire;
  if (rating === SecondaryRating.OUTDATED) return RatingOutdated;
  if (rating === SecondaryRating.SCAM) return RatingScam;
  if (rating === SecondaryRating.CAUTION_ADVISED) return RatingCaution;

  return RatingTrue;
};

const getRatingLabel = (rating: SecondaryRating, lang: "en" | "si" | "ta") => {
  switch (rating) {
    case SecondaryRating.FALSE:
      if (lang === "en") return "FALSE";
      if (lang === "si") return "අසත්‍ය පුවතක්";
      if (lang === "ta") return "தவறானது";
      break;
    case SecondaryRating.MISATTRIBUTED:
      if (lang === "en") return "MISATTRIBUTED";
      if (lang === "si") return "වැරදි උපුටාගැනීමක්";
      if (lang === "ta") return "தவறாகப் பகிரப்பட்டது";
      break;
    case SecondaryRating.MISLEADING:
      if (lang === "en") return "MISLEADING";
      if (lang === "si") return "නොමඟ යවන සුළු පුවතක්";
      if (lang === "ta") return "தவறாக வழிநடத்துகிறது";
      break;
    case SecondaryRating.MOSTLY_FALSE:
      if (lang === "en") return "MOSTLY FALSE";
      if (lang === "si") return "අසත්‍ය සම්භවයක් ඇති පුවතක්";
      if (lang === "ta") return "பெரும்பாலும் தவறானது";
      break;
    case SecondaryRating.OUTDATED:
      if (lang === "en") return "OUTDATED";
      if (lang === "si") return "යල් පැනගිය පුවතක්";
      if (lang === "ta") return "காலாவதியானது";
      break;
    case SecondaryRating.SATIRE:
      if (lang === "en") return "SATIRE";
      if (lang === "si") return "විකට ප්‍රවෘත්ති";
      if (lang === "ta") return "நையாண்டி";
      break;
    case SecondaryRating.SCAM:
      if (lang === "en") return "SCAM";
      if (lang === "si") return "ප්‍රෝඩාකාරී පුවතක්";
      if (lang === "ta") return "மோசடியானது";
      break;
    case SecondaryRating.CAUTION_ADVISED:
      if (lang === "en") return "CAUTION ADVISED";
      if (lang === "si") return "ප්‍රවේශමෙන්";
      if (lang === "ta") return "அவதானமாக இருங்கள்";
      break;
    case SecondaryRating.SENSATIONALIZED:
      if (lang === "en") return "SENSATIONALIZED";
      if (lang === "si") return "භාවප්‍රකෝපකාරී පුවතක්";
      if (lang === "ta") return "பரபரப்பானது";
      break;
    case SecondaryRating.VERIFIED:
      if (lang === "en") return "VERIFIED";
      if (lang === "si") return "සත්‍ය පුවතක්";
      if (lang === "ta") return "சரிபார்க்கப்பட்டது";
      break;
    default:
      return "";
  }
};

const COLOURS = {
  RED: "#B0281E",
  BLUE: "#2B4486",
  YELLOW: "#EDD765",
};

export const SocialMediaPost: FunctionComponent<any> =
  forwardRef<HTMLDivElement>(
    ({ data, lang, contentFontSize = 18 }: any, ref) => {
      const title = data[`${lang}_title`];
      const description = data[`${lang}_description`];
      return (
        <div
          ref={ref}
          style={{
            height: 1260 / 2,
            width: 1330 / 2,
            padding: 40,
            paddingBottom: 80,
            backgroundColor:
              COLOURS[data.primary_rating as keyof typeof COLOURS],
          }}
        >
          <div
            style={{
              borderRadius: 12,
              backgroundColor: "#ffff",
              height: "100%",
              width: "100%",
              padding: 30,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              {data?.secondary_rating.map((rating: SecondaryRating) => (
                <Tag
                  color={COLOURS[data.primary_rating as keyof typeof COLOURS]}
                >
                  {getIcon(rating) ? (
                    <img
                      className="icon"
                      src={getIcon(rating)}
                      width={11}
                      height={11}
                      alt="tag"
                    />
                  ) : null}{" "}
                  {getRatingLabel(rating, lang)}
                </Tag>
              ))}
            </div>
            <div
              style={{
                fontSize: 23,
                color: "B0281E",
                paddingTop: 10,
                fontWeight: "bold",
              }}
            >
              {title}
            </div>
            <div
              style={{
                paddingTop: 20,
                fontSize: contentFontSize,
              }}
            >
              {description}
            </div>
            <div
              style={{
                fontWeight: "bold",
                paddingTop: 20,
              }}
            >
              Source: {data?.source_name}
            </div>
          </div>
          <div
            style={{
              paddingTop: 20,
              paddingBottom: 20,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <div
              style={{
                color: "#fff",
                fontSize: 16,
                fontWeight: "normal",
                flex: "0.6",
              }}
            >
              https://www.watchdog.team/post?id=
              {data?.id}
            </div>
            <div
              style={{
                flex: "0.4",
                textAlign: "right",
              }}
            >
              <div>
                <img src={WatchdogLogo} width="55%" alt="Logo"></img>
              </div>
            </div>
          </div>
        </div>
      );
    }
  );
