import { ApolloProvider } from "@apollo/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

/* stores */
import { tokenStore } from "./Common/stores/token";

/* containers */
import { Header } from "./Common/containers/Header";

/* pages */

// auth
import { SignIn } from "./Auth/pages/SignIn";
import { SignOut } from "./Auth/pages/SignOut";

// posts
import { AllPosts } from "./Posts/pages/AllPosts";
import { CreatePost } from "./Posts/pages/CreatePost";
import { EditPost } from "./Posts/pages/EditPost";

// graphql
import { graphQLClient } from "./api/apollo";

import "./App.css";
import { SocialMediaPostPage } from "./Posts/pages/SocialMediaPage";

function App() {
  const token = tokenStore((state) => state.token);

  return (
    <ApolloProvider client={graphQLClient}>
      <div className="App">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-out" element={<SignOut />} />
            <Route path="/newPost" element={<CreatePost />} />
            <Route path="/" element={<AllPosts />} />
            <Route path="/post/:postId/edit" element={<EditPost />} />
            <Route
              path="/post/:postId/social-media"
              element={<SocialMediaPostPage />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </ApolloProvider>
  );
}

export default App;
