import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";
import { FunctionComponent } from "react";
import { Navigate, useParams } from "react-router-dom";

// queries
import { Post, PostsQueries } from "../../shared";

// partials
import { SinglePost } from "../partials/SinglePost";

export const EditPost: FunctionComponent = () => {
  const [updatePost, { loading: updating }] = useMutation(
    PostsQueries.updatePost
  );
  const { postId: postIdStr } = useParams();

  const postIdInt = postIdStr ? parseInt(postIdStr) : null;

  const { data, loading, refetch } = useQuery<{ getPost: Post }>(
    PostsQueries.getPost,
    {
      variables: {
        id: postIdInt,
      },
    }
  );

  if (!postIdStr) return <Navigate to="" />;
  if (loading || !data) return null;

  return (
    <div className="container">
      <div className="section-header">
        <h2>Edit Post</h2>
      </div>
      <SinglePost
        post={data.getPost}
        onSubmit={async (values) => {
          let parsed: { [key: string]: unknown } = { ...values };
          delete parsed["__typename"];
          delete parsed["author"];
          delete parsed["author_id"];
          delete parsed["id"];
          delete parsed["timestamp"];
          delete parsed["last_update_at"];

          try {
            await updatePost({
              variables: {
                data: {
                  id: postIdInt,
                  post: parsed,
                },
              },
            });
            refetch();

            message.success("Post updated!", 3);
          } catch (e) {}
        }}
        submitButtonText="Update Post"
        submitButtonLoading={updating}
      />
    </div>
  );
};
