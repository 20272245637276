import React, { FunctionComponent } from "react";
import { useMutation, gql, ApolloError } from "@apollo/client";

import { Form, Input, Button, message, Row, Layout } from "antd";
import { Formik, FormikErrors } from "formik";

// shared types
import { SignInInput, SignInPayload } from "../../shared";

// containers
import { AuthLayout } from "../../Common/containers/AuthLayout";

// stores
import { tokenStore } from "../../Common/stores/token";

// mutations
import { AuthMutations } from "../../shared";

// constants
const SIZE = "middle";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const SignInInputInitValues: SignInInput = {
	email: "",
	password: "",
};

export const SignIn: FunctionComponent = () => {
	const setToken = tokenStore((state) => state.setToken);
	const [ signIn, { loading }] = useMutation<{ signIn: SignInPayload }, { data: SignInInput }>(AuthMutations.signIn);

	// Validate sign in form
	const validate = (values: SignInInput): FormikErrors<SignInInput> => {
		const errors: FormikErrors<SignInInput> = {};

		// Validate Email
		if (!values.email) {
			errors.email = "Required";
		} else if (!emailRegex.test(values.email)) {
			errors.email = "Invalid Email Address";
		}

		// Password
		if (!values.password) {
			errors.password = "Required";
		}
		if (values.password && values.password.length < 6) {
			errors.password = "Your password should have at least 6 characters";
		}

		return errors;
	};

	const submitForm = async(credentials: SignInInput) => {
		try {
			const { data } = await signIn({ variables: { data: credentials }});
            if (data) setToken(data.signIn.token);
		} catch (e) {
			(e as ApolloError).graphQLErrors.forEach((error: { message: string }) => {
				message.error(error.message, 3);
			});
		}
	};

	return (
        <AuthLayout>
            <div className="container">
                <Formik
                    initialValues={SignInInputInitValues}
                    validate={validate}
                    onSubmit={submitForm}
                >
                    {({
                        values,
                        errors,
                        handleChange,
                        handleSubmit,
                    }) => (
                        <Form 
                            onFinish={handleSubmit}
                            layout="vertical"
                            colon={false}
                        >
                            <h2>Sign In</h2>
                            <Form.Item
                                label="Email"
                                help={errors.email}
                                validateStatus={errors.email ? "error" : ""}
                            >
                                <Input
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    size={SIZE}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Password"
                                help={errors.password}
                                validateStatus={errors.password ? "error" : ""}
                            >
                                <Input
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    type="password"
                                    size={SIZE}
                                />
                            </Form.Item>
                            <Form.Item colon={false}>
                                <Row>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={loading}
                                        size={SIZE}
                                        block
                                    >
                    Sign In
                                    </Button>
                                </Row>
                            </Form.Item>
                        </Form>
                    )}
                </Formik>
            </div>
        </AuthLayout>
	);
};
